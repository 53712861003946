
import { defineComponent, ref, watch, reactive } from "vue";
import Multiselect from "@vueform/multiselect";
import { CONSULTATION_CLASSIFICATION, SYMPTOMS, AREA } from "../core/enums";
import { useRouter } from "vue-router";
import {
  LineProfile,
  Store,
  StoreOption,
  StoreSelection,
} from "@/core/interfaces";
import { get } from "@/core/services/aoi-service";
import { useStore } from "@/core/store";
import tickIcon from "../assets/icons/check-solid.svg";
import { useLoading } from "vue-loading-overlay";
import liff from "@line/liff";
import config from "@/core/config";
import i18n from "@/i18n";
import { isLessThanMonth } from "@/core/utils";

export default defineComponent({
  name: "StoreSelection",
  components: {
    Multiselect,
  },
  setup() {
    const loading = useLoading();
    const apiLoaded = ref();
    const lang = ref();
    const version = ref();
    const isInClient = ref();
    const loggedIn = ref();
    //
    const router = useRouter();
    const store = useStore();
    let customer: any = null;
    const storeSelection = reactive<StoreSelection>(
      store.getters.storeSelection
    );
    const storeOptions = ref<Array<StoreOption>>([]);
    let loadingComponent: any = null;
    let treatmentMenuList: Array<any> = [];
    const errors = ref({
      store: "",
      consultationClassification: "",
      symptom: "",
      area: "",
    });
    const isOnMaintenance = ref();
    let storeList: Array<any> = [];
    //
    const reservationIsNotPossible = ref<boolean>(false);

    /**
     * Initialize LIFF
     * @param {string} myLiffId The LIFF ID of the selected element
     */
    const initializeLiff = (myLiffId: string) => {
      liff
        .init({
          liffId: myLiffId,
        })
        .then(() => {
          // start to use LIFF's api
          initializeApp();
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    if (config.lineAppId) {
      loadingComponent = loading.show();
      initializeLiff(config.lineAppId);
    } else {
      console.error("Cannot initialize line app: No Line App ID found");
    }

    const initializeApp = () => {
      lang.value = liff.getLanguage();
      version.value = liff.getVersion();
      isInClient.value = liff.isInClient();
      loggedIn.value = liff.isLoggedIn();
      localStorage.setItem("calendarPage", '0'); // Resetting value of calendarCount when starting the process from first step
      localStorage.setItem("Fullname", ''); // Resetting value of Fullname when starting the process from first step
      localStorage.setItem("Troubles", ''); // Resetting value of Troubles when starting the process from first step
      localStorage.setItem("CellNo", ''); // Resetting value of CellNo when starting the process from first step
      if (loggedIn.value) {
        store.commit("setToken", liff.getAccessToken());
        liff
          .getProfile()
          .then((profile) => {
            store.commit("setLineProfile", profile);
            checkForMaintenance()
              .then(() => {
                checkIfCustomerExists(profile);
              })
              .catch((error) => {
                loadingComponent.hide();
                router.push({
                  name: "Maintenance",
                  params: { message: error.message },
                });
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (!isInClient.value) {
          login();
        }
      }
    };

    /**
     * Check for maintenance of Line APP
     */
    const checkForMaintenance = () => {
      return new Promise((resolve, reject) => {
        get(`api/v1/line/check-maintenance`)
          .then((maintenanceResponse) => {
            if (maintenanceResponse.status === 200) {
              isOnMaintenance.value =
                maintenanceResponse.data?.data?.status === "1" ? true : false; // 1 means is on maintenance
              if (!isOnMaintenance.value) {
                resolve(maintenanceResponse.data?.data);
              } else {
                reject(maintenanceResponse.data?.data);
              }
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    };

    const checkIfCustomerExists = (profile: LineProfile) => {
      get(`/api/v1/line/customers`)
        .then((customerResponse) => {
          if (customerResponse.status === 200) {
            if (customerResponse?.data?.data?.length > 0) {
              store.commit("setCustomer", customerResponse.data.data[0]);
              customer = customerResponse.data.data[0];
            }
          }
          apiLoaded.value = true;
          loadingComponent.hide();
          if (typeof storeSelection.area !== "string") {
            getStoreList(storeSelection.area);
          }
          getTreatmentMenuList();
        })
        .catch((err) => {
          apiLoaded.value = true;
          loadingComponent.hide();
        });
    };

    const login = () => {
      liff.login();
    };

    /**
     * Perform operations when next button is clicked
     * 1. Check validation
     * 2. Check for reservation where reservation status is confirmed
     * 3. Check if customer number exists or not
     * 4. If customer doesnot exist, navigate to the respective page
     * 5. If customer exists, check for the consultation classification with customer details and perform validation ana navigation
     */
    const onNext = () => {
      const treatment = treatmentMenuList
        .filter(
          (treatmentMenu: {
            consultationClassification: number;
            displayOrder: number;
          }) =>
            treatmentMenu.consultationClassification ===
            storeSelection.consultationClassification
        )
        .sort((first, second) => first.displayOrder - second.displayOrder)[0];
      if (treatment) {
        store.commit("setTreatmentMenu", treatment);
      }
      if (!storeSelection.storeId) {
        errors.value.store = `${i18n.global.t("Errors.E112")}`;
      } else {
        errors.value.store = "";
      }
      if (!storeSelection.consultationClassification) {
        errors.value.consultationClassification = `${i18n.global.t(
          "Errors.E112"
        )}`;
      } else {
        errors.value.consultationClassification = "";
      }
      if (
        !storeSelection.symptom &&
        storeSelection.consultationClassification !==
          CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      ) {
        errors.value.symptom = `${i18n.global.t("Errors.E112")}`;
      } else {
        errors.value.symptom = "";
      }
      if (!storeSelection.area) {
        errors.value.area = `${i18n.global.t("Errors.E112")}`;
      } else {
        errors.value.area = "";
      }
      if (
        !storeSelection.symptom &&
        storeSelection.consultationClassification !==
          CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      ) {
        return;
      }

      storeSelection.store = storeList.filter(
        (store: any) => store.id === storeSelection.storeId
      )[0];
      store.commit("setStoreSelection", storeSelection);

      if (
        storeSelection.storeId &&
        storeSelection.consultationClassification &&
        storeSelection.area
      ) {
        checkLineReservationExists()
          .then(() => {
            reservationIsNotPossible.value = false;
            if (!customer || !customer.customerNumber) {
              switch (storeSelection.consultationClassification) {
                case CONSULTATION_CLASSIFICATION.FIRST_VISIT:
                  goToReservationCalendar();
                  break;
                case CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT:
                case CONSULTATION_CLASSIFICATION.RE_EXAMINATION:
                  goToLineIDLinkage();
                  break;
              }
            } else {
              switch (storeSelection.consultationClassification) {
                case CONSULTATION_CLASSIFICATION.FIRST_VISIT:
                  if (
                    customer.baoyeTreatmentIncludes ||
                    customer.trafficOrIndustrialTreatmentAvailable ||
                    customer.withAcupunture
                  ) {
                    if (isLessThanMonth(customer.lastVisitDate, 1)) {
                      reservationIsNotPossible.value = true;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L101"
                      )}`;
                    } else {
                      storeSelection.consultationClassification =
                        CONSULTATION_CLASSIFICATION.RE_EXAMINATION;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L102"
                      )}`;
                    }
                  } else {
                    if (isLessThanMonth(customer.lastVisitDate, 2)) {
                      storeSelection.consultationClassification =
                        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L103"
                      )}`;
                    } else {
                      storeSelection.consultationClassification =
                        CONSULTATION_CLASSIFICATION.RE_EXAMINATION;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L102"
                      )}`;
                    }
                  }
                  break;
                case CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT:
                  if (
                    customer.baoyeTreatmentIncludes ||
                    customer.trafficOrIndustrialTreatmentAvailable ||
                    customer.withAcupunture
                  ) {
                    if (isLessThanMonth(customer.lastVisitDate, 1)) {
                      reservationIsNotPossible.value = true;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L101"
                      )}`;
                    } else {
                      storeSelection.consultationClassification =
                        CONSULTATION_CLASSIFICATION.RE_EXAMINATION;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L102"
                      )}`;
                    }
                  } else {
                    if (isLessThanMonth(customer.lastVisitDate, 2)) {
                      goToReservationCalendar();
                    } else {
                      storeSelection.consultationClassification =
                        CONSULTATION_CLASSIFICATION.RE_EXAMINATION;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L102"
                      )}`;
                    }
                  }
                  break;
                case CONSULTATION_CLASSIFICATION.RE_EXAMINATION:
                  if (
                    customer.baoyeTreatmentIncludes ||
                    customer.trafficOrIndustrialTreatmentAvailable ||
                    customer.withAcupunture
                  ) {
                    if (isLessThanMonth(customer.lastVisitDate, 1)) {
                      reservationIsNotPossible.value = true;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L101"
                      )}`;
                    } else {
                      goToReservationCalendar();
                    }
                  } else {
                    if (isLessThanMonth(customer.lastVisitDate, 2)) {
                      storeSelection.consultationClassification =
                        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT;
                      errors.value.consultationClassification = `${i18n.global.t(
                        "Errors.L104"
                      )}`;
                    } else {
                      goToReservationCalendar();
                    }
                  }
                  break;
              }
            }
          })
          .catch((err) => {
            reservationIsNotPossible.value = true;
            errors.value.store = `${i18n.global.t("Errors.L100")}`;
            loadingComponent.hide();
          });
      }
    };

    /**
     * Check Line reservation already exists
     */
    const checkLineReservationExists = () => {
      return new Promise((resolve, reject) => {
        loadingComponent = loading.show();
        get(`/api/v1/line/reservations/check-confirm-reservation-by-line`)
          .then((res) => {
            const reservationList = res.data.data;
            if (reservationList?.length > 0) {
              reject("Reservation already exists");
            } else {
              loadingComponent.hide();
              resolve(res);
            }
          })
          .catch((err) => {
            loadingComponent.hide();
            reject(err);
          });
      });
    };

    const goToReservationCalendar = () => {
      router.push({ name: "ReservationCalendar" });
    };

    const goToLineIDLinkage = () => {
      router.push({ name: "LineIDLinkage" });
    };

    const getStoreList = (code: number) => {
      loadingComponent = loading.show();
      get(`/api/v1/line/getStoresByCode?code=${code}`)
        .then((res) => {
          if (res.status === 200) {
            storeList = res.data.data;
            storeOptions.value = storeList
              .filter((store: Store) => store.publicFlg)
              .sort(
                (storeA: Store, storeB: Store) =>
                  storeA.displayOrder - storeB.displayOrder
              )
              .map((store: Store) => {
                return {
                  value: store.id,
                  name: store.storeName,
                  icon: tickIcon,
                };
              });

            if (storeOptions.value.length > 0 && !storeSelection.storeId) {
              storeSelection.storeId = storeOptions.value[0].value;
            }
            loadingComponent.hide();
          }
        })
        .catch(() => {
          loadingComponent.hide();
        });
    };

    const onAreaSelect = () => {
      storeSelection.store = null;
      storeSelection.storeId = "";
      storeOptions.value = [];
      if (typeof storeSelection.area !== "string") {
        getStoreList(storeSelection.area);
      }
    };

    const getTreatmentMenuList = () => {
      get("/api/v1/line/treatment-menus").then((res) => {
        if (res.status === 200) {
          treatmentMenuList = res.data.data;
        }
      });
    };


    watch(
      () => storeSelection.area,
      (newArea, oldArea) => {
        if (newArea !== oldArea) {
          onAreaSelect();
        }
      }
    );

    watch(
      () => storeSelection.consultationClassification,
      (newConsultationClassification, oldConsultationClassification) => {
        if (
          newConsultationClassification ===
          CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ) {
          
          storeSelection.symptom = 1
          // loadingComponent = loading.show();
          // get(`/api/v1/line/symptom`)
          //   .then((res) => {
          //     if (res.status === 200) {
          //       storeSelection.symptom = res?.data.data.symptom;
          //       loadingComponent.hide();
          //     }
          //   })
          //   .catch(() => {
          //     loadingComponent.hide();
          //     storeSelection.symptom = "";
          //   });
        }
      }
    );

    watch(
      () => [
        storeSelection.consultationClassification,
        storeSelection.symptom,
        storeSelection.store,
      ],
      (newSelection, oldSelection) => {
        if (reservationIsNotPossible.value) {
          reservationIsNotPossible.value = false;
        }
      }
    );

    return {
      CONSULTATION_CLASSIFICATION,
      SYMPTOMS,
      AREA,
      //
      storeSelection,
      storeOptions,
      errors,
      reservationIsNotPossible,
      //
      onAreaSelect,
      onNext,
    };
  },
});
