
import { defineComponent, ref, onMounted } from "vue";
import { LineProfile } from "@/core/interfaces";
import { store } from "../core/store";
import { get } from "@/core/services/aoi-service";
import constants from "@/core/constants";
import moment from "moment";
import { useLoading } from "vue-loading-overlay";
import liff from "@line/liff";
import config from "@/core/config";
import { useRouter } from "vue-router";

moment.locale("ja");

export default defineComponent({
  name: "CurrentReservation",
  setup() {
    const loading = useLoading();
    const route = useRouter();
    const apiLoaded = ref();
    const lang = ref();
    const version = ref();
    const isInClient = ref();
    const loggedIn = ref();
    const reservation = ref();
    const isReservationConfirmed = ref<boolean>(false);
    const hasReservationError = ref<boolean>(false);
    const reservationConfirmationTitle = ref<string>("");
    const reservationConfirmationText = ref<string>("");
    let loadingComponent: any = null;

    /**
     * Initialize LIFF
     * @param {string} myLiffId The LIFF ID of the selected element
     */
    const initializeLiff = (myLiffId: string) => {
      liff
        .init({
          liffId: myLiffId,
        })
        .then(() => {
          // start to use LIFF's api
          initializeApp();
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    if (config.lineAppId) {
      loadingComponent = loading.show();
      initializeLiff(config.lineAppId);
    } else {
      console.error("Cannot initialize line app: No Line App ID found");
    }

    const initializeApp = () => {
      lang.value = liff.getLanguage();
      version.value = liff.getVersion();
      isInClient.value = liff.isInClient();
      loggedIn.value = liff.isLoggedIn();

      if (loggedIn.value) {
        store.commit("setToken", liff.getAccessToken());
        liff
          .getProfile()
          .then((profile) => {
            store.commit("setLineProfile", profile);
            checkIfCustomerReservationExists();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (!isInClient.value) {
          login();
        }
      }
    };

    const login = () => {
      liff.login({ redirectUri: window.location.href });
    };

    const checkIfCustomerReservationExists = () => {
      get(`/api/v1/line/reservations/latest-by-lineid`)
        .then((customerReservationResponse) => {
          if (customerReservationResponse.status === 200) {
            reservation.value = customerReservationResponse.data.data;
            isReservationConfirmed.value = true;
            if(customerReservationResponse.data.data.length <= 0){
              isReservationConfirmed.value = false;
              hasReservationError.value = true;
              apiLoaded.value = true;
            }
          }
          apiLoaded.value = true;
          loadingComponent.hide();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            isReservationConfirmed.value = false;
            hasReservationError.value = true;
            apiLoaded.value = true;
            loadingComponent.hide();
          }
        });
    };

    const getJapaneseTextDate = (date: string) => {
      return moment(date).format("LL (dd)");
    };

    const getTime = (time: string) => {
      return moment(time, "HH:mm:ss").format("HH:mm");
    };

    const close = () => {
      if (liff.isInClient()) {
        liff.closeWindow();
      }
    };

    onMounted(() => {
      get(
        `/api/v1/line/page-guide?code=${constants.LIFF_APP_RESERVATION_CONFIRMATION_SCREEN_CODE}`
      ).then((res) => {
        if (res.status === 200) {
          reservationConfirmationTitle.value = res.data?.data?.title || "";
          reservationConfirmationText.value = res.data?.data?.sentence || "";
        }
      });
    });

    return {
      reservation,
      reservationConfirmationTitle,
      reservationConfirmationText,
      isReservationConfirmed,
      apiLoaded,
      hasReservationError,
      //
      getJapaneseTextDate,
      getTime,
      close,
    };
  },
});
