import { createApp } from "vue";
import App from "./App.vue";
import { store, key } from "@/core/store";
import router from "./router";
import i18n from "./i18n";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

createApp(App)
  .use(i18n)
  .use(store, key)
  .use(router)
  .use(VueLoading)
  .mount("#app");
