import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61636be0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-screen" }
const _hoisted_2 = { class: "mobile-box" }
const _hoisted_3 = {
  key: 0,
  class: "page-title"
}
const _hoisted_4 = {
  key: 1,
  class: "page-title"
}
const _hoisted_5 = {
  key: 2,
  class: "text-danger text-center d-block"
}
const _hoisted_6 = {
  key: 3,
  class: "note-section"
}
const _hoisted_7 = {
  key: 4,
  class: "confirmation-reservation"
}
const _hoisted_8 = { class: "reservation-details-heading" }
const _hoisted_9 = { class: "troublesome-symptoms" }
const _hoisted_10 = {
  key: 0,
  class: "text-danger text-center d-block"
}
const _hoisted_11 = {
  key: 1,
  class: "next-prev-buttons d-flex justify-content-between mt-4 pt-2"
}
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isReservationConfirmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ConfirmationTitle")), 1))
        : _createCommentVNode("", true),
      (_ctx.isReservationConfirmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.AcceptanceTitle")), 1))
        : _createCommentVNode("", true),
      (!_ctx.isReservationConfirmed)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.NotConfirmedYet")), 1))
        : _createCommentVNode("", true),
      (_ctx.isReservationConfirmed)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.reservationConfirmationTitle), 1))
        : _createCommentVNode("", true),
      (_ctx.isReservationConfirmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.reservationConfirmationText), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationDetails")), 1),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationDate")) + " : " + _toDisplayString(_ctx.getJapaneseTextDate(_ctx.reservationInfoForm.date)) + " " + _toDisplayString(_ctx.getTime(_ctx.reservationInfoForm.startTime)), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationStore")) + " : " + _toDisplayString(_ctx.storeSelection.store.storeName), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("EnterReservationInformation.MainHospitalName")) + " : " + _toDisplayString(_ctx.reservationInfoForm.customerName), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("EnterReservationInformation.PhoneNumber")) + " : " + _toDisplayString(_ctx.reservationInfoForm.cellNo), 1)
      ]),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.TroublesomeSymptoms")) + ": ", 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.reservationInfoForm.symptomAndTouble), 1)
    ]),
    (!_ctx.isReservationConfirmedBtnActive)
      ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t("Errors.L106")), 1))
      : _createCommentVNode("", true),
    (!_ctx.isReservationConfirmed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("button", {
            class: "btn prev-button d-block",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
          }, _toDisplayString(_ctx.$t("EnterReservationInformation.Return")), 1),
          _createElementVNode("button", {
            class: "btn btn-primary d-block",
            disabled: !_ctx.isReservationConfirmedBtnActive,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirmReservation && _ctx.confirmReservation(...args)))
          }, _toDisplayString(_ctx.$t("MakeAReservationWithTheAboveContents")), 9, _hoisted_12)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isReservationConfirmed)
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          class: "btn btn-primary d-block mx-auto search-button",
          href: "#",
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.completeRegistration && _ctx.completeRegistration(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.Close")), 1))
      : _createCommentVNode("", true)
  ]))
}