
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Maintenance",
  components: {},
  setup() {
    const route = useRoute();
    const resumptionDate = ref(route.params.message || "");
    return {
      message: resumptionDate,
    };
  },
});
