
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { GENDER_TYPE } from "../core/enums";
import { LineIDLinkage } from "@/core/interfaces";
import { useStore } from "@/core/store";
import { useLoading } from "vue-loading-overlay";
import { post } from "@/core/services/aoi-service";
import moment from "moment";
import i18n from "@/i18n";

export default defineComponent({
  name: "LineIDLinkage",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const apiLoading = ref<boolean>(false);
    const invalidCustomerDetailForLineIDLinkage = ref<boolean>(false);
    const yearData = ref<any>("");
    const lineProfile = store.getters.lineProfile;
    const lineIDLinkage = ref<LineIDLinkage>(store.getters.lineIDLinkage);
    const loading = useLoading();
    let loadingComponent: any = null;
    const errors = ref({
      customerNumber: "",
      name: "",
      dob: "",
      phoneOrMobileNumber: "",
    });

    const goToReservationCalendar = () => {
      if (apiLoading.value) {
        return;
      }
      if (!lineIDLinkage.value.customerNumber) {
        errors.value.customerNumber = `${i18n.global.t("Errors.E061")}`;
      }
      if (
        !lineIDLinkage.value.dob.year ||
        !lineIDLinkage.value.dob.month ||
        !lineIDLinkage.value.dob.date
      ) {
        errors.value.dob = `${i18n.global.t("Errors.E061")}`;
      }
      if (!lineIDLinkage.value.phoneOrMobileNumber) {
        errors.value.phoneOrMobileNumber = `${i18n.global.t("Errors.E061")}`;
      }

      apiLoading.value = true;
      invalidCustomerDetailForLineIDLinkage.value = false;

      loadingComponent = loading.show();

      if (
        !lineIDLinkage.value.customerNumber ||
        !lineIDLinkage.value.phoneOrMobileNumber ||
        !lineIDLinkage.value.dob.year ||
        !lineIDLinkage.value.dob.month ||
        !lineIDLinkage.value.dob.date
      ) {
        loadingComponent.hide();
        apiLoading.value = false;
        return;
      } else {
        if (
          Number(lineIDLinkage.value.dob.year) &&
          Number(lineIDLinkage.value.dob.month) &&
          Number(lineIDLinkage.value.dob.date)
        ) {
          post(`/api/v1/line/customers-line-linkage`, {
            lineId: lineProfile.userId,
            customerId: lineIDLinkage.value.customerNumber,
            phoneNumber: lineIDLinkage.value.phoneOrMobileNumber,
            birthDate: moment()
              .set({
                year: Number(lineIDLinkage.value.dob.year),
                month: Number(lineIDLinkage.value.dob.month) - 1,
                date: Number(lineIDLinkage.value.dob.date),
              })
              .format("YYYY-MM-DD"),
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.data.data) {
                  store.commit("setCustomer", res.data.data);
                  store.commit("setLineIDLinkage", lineIDLinkage.value);
                  router.push({ name: "ReservationCalendar" });
                }
              }
              loadingComponent.hide();
              apiLoading.value = false;
            })
            .catch((err) => {
              if (err.response.status === 404 || err.response.status === 422) {
                // show customer cannot be linked with the provided info
                invalidCustomerDetailForLineIDLinkage.value = true;
              }
              loadingComponent.hide();
              apiLoading.value = false;
            });
        } else {
          loadingComponent.hide();
          apiLoading.value = false;
        }
      }
    };

    const goBack = () => {
      router.back();
    };

    onMounted(() => {
      const YEARS = () => {
        const numYears = 122;
        const years: any = [];
        const start = moment();
        const months = Array.from({ length: numYears }, (_, i) =>
          moment(start.subtract(1, "year")).format("YYYY")
        ).reverse();
        if (months.length > 0) {
          const monthsData = months.forEach((dates: any) => {
            years.push({
              value: dates,
              name: dates,
            });
          });
          yearData.value = years;
        }
      };

      YEARS();
    });

    return {
      GENDER_TYPE,
      //
      invalidCustomerDetailForLineIDLinkage,
      apiLoading,
      lineIDLinkage,
      errors,
      goBack,
      //
      goToReservationCalendar,
      yearOptions: yearData,
    };
  },
});
