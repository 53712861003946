import moment from "moment";

export const convertToFullWidthKana = (s: string) => {
  return s.normalize("NFKC");
};

export const testNumber = (s: string) => {
  const regex = /^\d+$/;
  return regex.test(s);
};

export const isLessThanMonth = (
  fromCheckDate: string,
  noOfMonths: number,
  currentDate?: string
) => {
  const currDate = currentDate ? currentDate : moment().format("YYYY-MM-DD");
  return moment(fromCheckDate, "YYYY-MM-DD").isAfter(
    moment(
      moment(currDate, "YYYY-MM-DD").subtract(noOfMonths, "months"),
      "YYYY-MM-DD"
    )
  );
};
