
import { defineComponent, ref, reactive, onMounted } from "vue";
import {
  LineProfile,
  ReservationInfo,
  StoreSelection,
} from "@/core/interfaces";
import { store } from "../core/store";
import { useRouter } from "vue-router";
import { get, post } from "@/core/services/aoi-service";
import constants from "@/core/constants";
import moment from "moment";
import {
  RESERVATION_ROUTE,
  RESERVATION_STATUS,
  RESERVATION_TYPE,
} from "@/core/enums";
import { useLoading } from "vue-loading-overlay";
import liff from "@line/liff";

moment.locale("ja");

export default defineComponent({
  name: "ReservationConfirmation",
  setup() {
    const router = useRouter();
    let isReservationConfirmed = ref<boolean>(false);
    const isReservationConfirmedBtnActive = ref<boolean>(true);
    const reservationConfirmationTitle = ref<string>("");
    const reservationConfirmationText = ref<string>("");

    const lineProfile = reactive<LineProfile>(store.getters.lineProfile);
    const storeSelection = reactive<StoreSelection>(
      store.getters.storeSelection
    );
    const { customer, treatmentMenu } = store.getters;
    const reservationInfoForm = reactive<ReservationInfo>(
      store.getters.reservationInfo
    );
    let confirmReservationBtnClick = ref<boolean>(false);
    const loading = useLoading();
    let loadingComponent: any = null;

    const getJapaneseTextDate = (date: string) => {
      return moment(date).format("LL (dd)");
    };

    const getTime = (time: string) => {
      return moment(time, "HH:mm:ss").format("HH:mm");
    };

    const goBack = () => {
      router.back();
    };

    const confirmReservation = async () => {
      if (
        confirmReservationBtnClick.value ||
        !isReservationConfirmedBtnActive.value
      ) {
        return;
      }
      confirmReservationBtnClick.value = true;
      loadingComponent = loading.show();
      let formData: any = {
        is_blocked: true,
        clicked_time: moment().format('HH:mm'),
        line_id: lineProfile.userId,
        date: reservationInfoForm.date,
        store_id: storeSelection.store.id,
        treatment_menu_id: treatmentMenu?.id,
        starting_time: reservationInfoForm.startTime,
        ending_time: reservationInfoForm.endTime,
        reservation_type: RESERVATION_TYPE.RESERVE,
        reservation_person: reservationInfoForm.customerName,
        telephone_number: reservationInfoForm.cellNo,
        consultation_classification: storeSelection.consultationClassification,
        remarks: reservationInfoForm.symptomAndTouble,
        reservation_status: RESERVATION_STATUS.CONFIRMED,
        reservation_route: RESERVATION_ROUTE.LINE,
        schedule: reservationInfoForm.schedule,
      };
      if (customer?.customerNumber) {
        formData = {
          ...formData,
          customer_number: customer.customerNumber,
        };
      }
      if (storeSelection.symptom) {
        formData = {
          ...formData,
          symptom: storeSelection.symptom,
        };
      }

      try {
        const response = await post("/api/v1/line/reservations", formData);
        if (response.status === 200) {
          isReservationConfirmed.value = true;
          confirmReservationBtnClick.value = false;
          loadingComponent.hide();
          localStorage.setItem("Fullname", ''); 
          localStorage.setItem("Troubles", '');
          localStorage.setItem("CellNo", '');
        }
      } catch (err: any) {
        loadingComponent.hide();
        confirmReservationBtnClick.value = false;
        if (err?.response.status === 400) {
          isReservationConfirmedBtnActive.value = false;
        }
      }
    };

    const completeRegistration = () => {
      if (liff.isInClient()) {
        liff.closeWindow();
      } else {
        router.push({ name: "StoreSelection" });
      }
    };

    onMounted(() => {
      get(
        `/api/v1/line/page-guide?code=${constants.LIFF_APP_RESERVATION_CONFIRMATION_SCREEN_CODE}`
      ).then((res) => {
        if (res.status === 200) {
          reservationConfirmationTitle.value = res.data?.data?.title || "";
          reservationConfirmationText.value = res.data?.data?.sentence|| "";
        }
      });
    });

    return {
      reservationConfirmationTitle,
      reservationConfirmationText,
      isReservationConfirmed,
      isReservationConfirmedBtnActive,
      reservationInfoForm,
      storeSelection,
      //
      getJapaneseTextDate,
      getTime,
      goBack,
      confirmReservation,
      completeRegistration,
    };
  },
});
