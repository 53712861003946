import { AxiosRequestConfig } from "axios";
import { httpBase } from "./http-service";

export function post(endpoint: any, data: any, config?: AxiosRequestConfig) {
  return config
    ? httpBase().post(`${endpoint}`, data, config)
    : httpBase().post(`${endpoint}`, data);
}

export function put(endpoint: any, data: any, config?: AxiosRequestConfig) {
  return config
    ? httpBase().put(`${endpoint}`, data, config)
    : httpBase().put(`${endpoint}`, data);
}

export function get(endpoint: any, config?: AxiosRequestConfig) {
  return config
    ? httpBase().get(`${endpoint}`, config)
    : httpBase().get(`${endpoint}`);
}

// export function remove(endpoint: string, config?: AxiosRequestConfig) {
//   return config
//     ? httpBase().delete(`${endpoint}`)
//     : httpBase().delete(`${endpoint}`);
// }
