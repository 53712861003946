import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5274a200"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-screen" }
const _hoisted_2 = {
  key: 0,
  class: "mobile-box"
}
const _hoisted_3 = { class: "page-title" }
const _hoisted_4 = {
  key: 0,
  class: "note-section"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "reservation-details-heading" }
const _hoisted_7 = { class: "indexCount" }
const _hoisted_8 = { class: "troublesome-symptoms" }
const _hoisted_9 = {
  key: 0,
  class: "break-line"
}
const _hoisted_10 = {
  key: 1,
  class: "confirmation-number text-primary fw-bold text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isReservationConfirmed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.AcceptanceTitle")), 1),
          (_ctx.reservationConfirmationTitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.reservationConfirmationTitle), 1))
            : _createCommentVNode("", true),
          (_ctx.reservationConfirmationText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.reservationConfirmationText), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reservation, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "outer-div",
              key: item
            }, [
              _createElementVNode("p", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(index+1), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationDetails")), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationDate")) + " : " + _toDisplayString(_ctx.getJapaneseTextDate(item.date)) + " " + _toDisplayString(_ctx.getTime(item.startingTime)), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationStore")) + " : " + _toDisplayString(item.store.storeName), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("EnterReservationInformation.MainHospitalName")) + " : " + _toDisplayString(item.customer
                ? `${item.customer.kanaSurname} ${item.customer.katakanaName}`
                : `${item.reservationPerson}`), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("EnterReservationInformation.PhoneNumber")) + " : " + _toDisplayString(item.phone), 1)
              ]),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.TroublesomeSymptoms")) + ": ", 1),
              _createElementVNode("div", null, _toDisplayString(item.remarks), 1),
              (index < (_ctx.reservation.length -1))
                ? (_openBlock(), _createElementBlock("hr", _hoisted_9))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasReservationError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.NoReservationText")), 1))
      : _createCommentVNode("", true),
    (_ctx.apiLoaded)
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          class: "btn btn-primary d-block mx-auto search-button",
          href: "#",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.Close")), 1))
      : _createCommentVNode("", true)
  ]))
}