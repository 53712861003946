import axios from "axios";
import { store } from "@/core/store";

export const httpBase = () => {
  const normalHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Line-Access": store.getters.token,
  };

  const http = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_API_URL}`,
    headers: { ...normalHeaders },
    responseType: "json",
  });

  return http;
};
