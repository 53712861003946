import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44170845"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-screen during-maintenance" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "resumption-text text-center text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("DuringMaintenance.Title")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("DuringMaintenance.Resumption")), 1),
      _createElementVNode("div", null, [
        _createElementVNode("u", null, _toDisplayString(_ctx.message), 1)
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("DuringMaintenance.Schedule")), 1)
    ])
  ]))
}