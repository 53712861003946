
import { defineComponent, ref, onMounted, reactive } from "vue";
import "@fullcalendar/core/vdom";
import FullCalendar, { CalendarOptions, EventApi } from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useRouter } from "vue-router";
import { get } from "@/core/services/aoi-service";
import { useStore } from "@/core/store";
import {
  ScheduleHistory,
  ScheduleHistoryData,
  StoreSelection,
  lineTimeHistory,
} from "@/core/interfaces";
import circle from "../assets/icons/circle.svg";
import cross from "../assets/icons/cross1.svg";
import triangle from "../assets/icons/triangle.svg";
import moment, { Moment } from "moment";
import {
  CONSULTATION_CLASSIFICATION,
  PRACTITIONER_AVAILABILITY,
} from "@/core/enums";
import { useLoading } from "vue-loading-overlay";
import { convertToFullWidthKana } from "@/core/utils/index";

// import circle from "../assets/icons/return.svg;

export default defineComponent({
  name: "ReservationCalendar",
  components: {
    FullCalendar,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const storeSelection = reactive<StoreSelection>(
      store.getters.storeSelection
    );
    let trial : Array<any> = [];
    let lineTime : lineTimeHistory | null = null;
    let  currentThirtyHour : any;
    const { customer, treatmentMenu } = store.getters;
    const initialWeekStartDate = ref();
    const initialWeekEndDate = ref();
    const currentCalendarStartDate = ref();
    const currentCalendarEndDate = ref();
    let nextWeekClickCount = 0; //Counter for the number of times next week button is clicked to know on which page of the calendar the user is 
    const loading = useLoading();
    let loadingComponent: any = null;

    const fullCalendar = ref<InstanceType<typeof FullCalendar>>();
    let staffScheduleHistory: ScheduleHistory | null = null;
    let scheduleHistoryData: Array<ScheduleHistoryData> | null = null;

    const selectEvent = (args: any) => {
      if (
        args.event.extendedProps.practitionerAvaibility.count !==
        PRACTITIONER_AVAILABILITY.NOT_AVAILABLE
      ) {
        const start = moment(args.event.start);
        store.commit("setReservationInfo", {
          date: start.format("YYYY-MM-DD"),
          startTime: start.format("HH:mm"),
          endTime: treatmentMenu.requiredTime
            ? start
                .clone()
                .add(
                  moment.duration(treatmentMenu.requiredTime).asMinutes(),
                  "minutes"
                )
                .format("HH:mm")
            : start
                .clone()
                // .add(
                //   storeSelection.consultationClassification ===
                //     CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
                //     ? 30
                //     : 60,
                //   "minutes"
                // ),
                .add(30, "minutes"),
          schedule: args.event.extendedProps.practitionerAvaibility.schedule,
          customerName: customer
            ? convertToFullWidthKana(
                `${customer.surname} ${customer.name}`
              ).trim()
            : "",
          cellNo: customer
            ? `${customer.cellphoneNumber || customer.telephoneNumber}`
            : "",
          symptomAndTouble: "",
        });
        if (
          storeSelection.consultationClassification ===
          CONSULTATION_CLASSIFICATION.FIRST_VISIT
        ) {
          router.push({ name: "ReservationInformation" });
        } else {
          router.push({ name: "ReservationConfirmation" });
        }
      }
    };

    const calendarOptions = reactive({
      editable: false,
      allDaySlot: false,
      firstDay: new Date().getDay(),
      // timeZone: "UTC",
      locale: "ja",
      headerToolbar: {
        left: "",
        center: "title",
        right: "",
      },
      titleFormat: { year: "numeric", month: "numeric" },
      dayHeaderFormat: {
        day: "numeric",
        weekday: "short",
      },
      dayHeaderContent: (dayHeaderContent) =>
        dayHeaderContent.text.replace("日", "\n"),
      contentHeight: 912,
      slotMinTime: "09:00",
      slotMaxTime: "22:00",
      slotLabelInterval: "00:30",
      // storeSelection.consultationClassification ===
      // CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      //   ? "00:30"
      //   : "00:60",
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        meridiem: false,
        hour12: false,
      },
      plugins: [
        timeGridPlugin,
        interactionPlugin, // needed for dateClick
      ],
      expandRows: true,
      displayEventTime: false,
      slotEventOverlap: false,
      events: [],
      selectable: false,
      longPressDelay: 0,
      eventClick: selectEvent,
      eventClassNames: function(arg) {
        if (arg.event.extendedProps.practitionerAvaibility.count === PRACTITIONER_AVAILABILITY.NOT_AVAILABLE) {
          return ['event-cross'];
        } else if (arg.event.extendedProps.practitionerAvaibility.count === PRACTITIONER_AVAILABILITY.ONLY_ONE_AVAILABLE) {
          return ['event-circle'];
        } else if (arg.event.extendedProps.practitionerAvaibility.count === PRACTITIONER_AVAILABILITY.MORE_THAN_TWO_AVAILABLE) {
          return ['event-circle'];
        }
      },
      // eventContent: function (arg) {
      //   let div = document.createElement("span");
      //   div.classList.add("event-img");
      //   let img = document.createElement("img");
      //   img.classList.add('imageTag')

      //   switch (arg.event.extendedProps.practitionerAvaibility.count) {
      //     case PRACTITIONER_AVAILABILITY.NOT_AVAILABLE:
      //       div.style.backgroundColor = "#F0F2F0";
      //       img.src = cross;
      //       img.width = 10;
      //       img.height = 10;
      //       div.classList.add("event-img-block");
      //       break;
      //     case PRACTITIONER_AVAILABILITY.ONLY_ONE_AVAILABLE:
      //       img.width = 14;
      //       img.height = 11;
      //       img.src = triangle;
      //       break;
      //     case PRACTITIONER_AVAILABILITY.MORE_THAN_TWO_AVAILABLE:
      //       img.src = circle;
      //       img.width = 16;
      //       img.height = 16;
      //       break;
      //   }
      //   img.style.width = "16px";
      //   div.append(img);
      //   let arrayOfDomNodes = [div];
      //   return { domNodes: arrayOfDomNodes };
      // },
    } as CalendarOptions);

    const getPractitionerAvaibility = (
      start: string,
      end: string,
    ): { schedule: Array<any>; count: PRACTITIONER_AVAILABILITY , practionarAvailableCount? : number , totalPractionarCount?: any} => {
      const eventStartTime = moment(start);
      const eventEndTime = moment(end);
      const date = eventStartTime.format("YYYY-MM-DD");
      const {
        store: { storeBusinessHours },
      } = storeSelection;
       const currentMoment = moment();
       if(parseInt(currentMoment.format("mm")) >= 30){
        currentThirtyHour = currentMoment.clone().set({
        hour: currentMoment.hour(),
        minutes: 60,
       });
      } else {
        currentThirtyHour = currentMoment.clone().set({
        hour: currentMoment.hour(),
        minutes: 30,
       });
      }

      if (eventStartTime.isSameOrBefore(currentThirtyHour) ||  eventStartTime.format('HH:mm:ss') === '09:00:00') {
        return {
          schedule: [],
          count: PRACTITIONER_AVAILABILITY.NOT_AVAILABLE,
        };
      } else {
        if (
          storeBusinessHours.length > 0 &&
          storeBusinessHours.filter(
            (businessHour: {
              day: number;
              isRegularHoliday: boolean;
              lineStartTime: string;
              lineEndTime: string;
            }) =>
              businessHour.day === eventStartTime.day() &&
              (businessHour.isRegularHoliday || moment(`${date} ${businessHour.lineStartTime}`).isAfter(eventStartTime))
          ).length > 0
        )
          return {
            schedule: [],
            count: PRACTITIONER_AVAILABILITY.NOT_AVAILABLE,
          };

        if (
          storeBusinessHours.length > 0 &&
          storeBusinessHours.filter(
            (businessHour: {
              day: number;
              isRegularHoliday: boolean;
              lineStartTime: string;
              lineEndTime: string;
            }) =>
              businessHour.day === eventStartTime.day() &&
              (businessHour.isRegularHoliday || moment(`${date} ${businessHour.lineEndTime}`).isBefore(eventEndTime))
          ).length > 0
        )
          return {
            schedule: [],
            count: PRACTITIONER_AVAILABILITY.NOT_AVAILABLE,
          };

        if (!staffScheduleHistory) {
          return {
            schedule: [],
            count: PRACTITIONER_AVAILABILITY.NOT_AVAILABLE,
          };
        } else {
          const filteredSchedule = staffScheduleHistory.staffSchedule.filter(
            (schedule, index) => {
              const workScheduleStart = moment(schedule.workStart);
              let workScheduleEnd = moment(lineTime!.lineSchedule[index].endTime);

              // lineTime!.lineSchedule.filter((lineTime) => {
              //   workScheduleEnd = moment(lineTime.endTime);
              // })
              // const workScheduleEnd = moment(schedule.workEnd); // Previously used this when maxentime was taken from filterschedule
              // scheduleHistoryData.filter(i =>{
              // })
        
              // const workScheduleBreakStart = moment(
              //   `${date} ${schedule.breakStart}`
              // );
              // const workScheduleBreakEnd = moment(
              //   `${date} ${schedule.breakEnd}`
              // );
              return (
                // Check for the event date IS SAME AS the scheduled work date
                schedule.date === date &&
                //
                // AND
                //
                // ( Check if the event start time IS SANE AS the scheduled work start time
                // OR
                // Check if the event start time IS BETWEEN scheduled work start time and scheduled work end time
                // )
                (eventStartTime.isSame(workScheduleStart) ||
                  eventStartTime.isBetween(
                    workScheduleStart,
                    workScheduleEnd
                  )) &&
                //
                // AND
                //
                // ( Check if the event start time IS NOT SANE AS the scheduled break start time
                // OR
                // Check if the event start time IS NOT BETWEEN scheduled break start time and scheduled break end time
                // )
                // !eventStartTime.isSame(workScheduleBreakStart) &&
                // !eventStartTime.isBetween(
                //   workScheduleBreakStart,
                //   workScheduleBreakEnd
                // ) &&
                //
                // AND
                //
                // ( Check if the event end time IS SANE AS the scheduled work end time
                // OR
                // Check if the event end time IS BETWEEN scheduled start time and scheduled end time
                // )
                (eventEndTime.isSame(workScheduleEnd) ||
                  eventEndTime.isBetween(workScheduleStart, workScheduleEnd)) &&
                //
                // AND
                //
                // ( Check if the event end time IS NOT SANE AS the scheduled break end time
                // OR
                // Check if the event end time IS NOT BETWEEN scheduled break end time and scheduled break end time
                // )
                // !eventEndTime.isSame(workScheduleBreakEnd) &&
                // !eventEndTime.isBetween(
                //   workScheduleBreakStart,
                //   workScheduleBreakEnd
                // ) &&
                //
                // AND
                //
                // Check if the practitioner is not assigned to any of the reservation for the event start time and event end time
                !practitionerAlreadyAssignedToReservationTaskOrHoliday(
                  eventStartTime,
                  eventEndTime,
                  schedule.date,
                  schedule.employeeId
                )
              );
            }
          );
     
          return {
            schedule: filteredSchedule,
            count:
              filteredSchedule.length === 0
                ? PRACTITIONER_AVAILABILITY.NOT_AVAILABLE
                : filteredSchedule.length === 1
                ? PRACTITIONER_AVAILABILITY.ONLY_ONE_AVAILABLE
                : PRACTITIONER_AVAILABILITY.MORE_THAN_TWO_AVAILABLE,
            practionarAvailableCount: filteredSchedule.length,
            totalPractionarCount: getTotalPractionarCount(date)
          };
        }
      }
    };

    const getTotalPractionarCount = (date: string) => {
      const practitionerReservationSchedule = scheduleHistoryData?.filter(
        (sHistory) =>
          sHistory.date === date
      );
      return practitionerReservationSchedule!.length;
    }

    const practitionerAlreadyAssignedToReservationTaskOrHoliday = (
      momentStart: Moment,
      momentEnd: Moment,
      date: string,
      practitionerId: number
    ): boolean => {
      const practitionerReservationSchedule = scheduleHistoryData?.filter(
        (sHistory) =>
          sHistory.date === date && sHistory.practitionerId === practitionerId
      )[0];
      const reservationList =
        practitionerReservationSchedule?.reservationInfo.filter((reserve) => {
          const start = moment(`${date}T${reserve.startTime}`);
          const end = moment(`${date}T${reserve.endTime}`);
          if(trial.filter(reserveDates => {
            return reserveDates.date === date && reserveDates.startTime === reserve.startTime && reserveDates.practitionerId === reserve.practitionerId && reserveDates.type === 'Reserve'
          }).length === 0){
            trial.push({...reserve, date, type: 'Reserve', practitionerId: reserve.practitionerId});
          }
          return (
            momentStart.isSame(start) ||
            momentStart.isBetween(start, end) ||
            momentEnd.isSame(end) ||
            momentEnd.isBetween(start, end)
          );
        });

      const taskList = practitionerReservationSchedule?.taskInfo.filter(
        (reserve) => {
          const start = moment(`${date}T${reserve.startTime}`);
          const end = moment(`${date}T${reserve.endTime}`);
          if(trial.filter(reserveDates => {

            return reserveDates.date === date && reserveDates.startTime === reserve.startTime && reserveDates.practitionerId === reserve.practitionerId && reserveDates.type === 'Task'
          }).length === 0){
            trial.push({...reserve, date, type: 'Task', practitionerId: reserve.practitionerId});
          }
          return (
            momentStart.isSame(start) ||
            momentStart.isBetween(start, end) ||
            momentEnd.isSame(end) ||
            momentEnd.isBetween(start, end)
          );
        }
      );

      const holidayList = practitionerReservationSchedule?.holidayInfo.filter(
        (reserve) => {
          const start = moment(`${date}T${reserve.startTime}`);
          const end = moment(`${date}T${reserve.endTime}`);
          if(trial.filter(reserveDates => {
            return reserveDates.date === date && reserveDates.startTime === reserve.startTime && reserveDates.practitionerId === reserve.practitionerId && reserveDates.type === 'Holiday'
          }).length === 0) {
            trial.push({...reserve, date, type: 'Holiday', practitionerId: reserve.practitionerId});
          }
          return (
            momentStart.isSame(start) ||
            momentStart.isBetween(start, end) ||
            momentEnd.isSame(end) ||
            momentEnd.isBetween(start, end)
          );
        }
      );

      if (reservationList || taskList || holidayList) {
        let reservationExists = false;
        if (reservationList && reservationList.length) {
          reservationExists = reservationList.length > 0;
        }
        if (taskList && taskList.length) {
          reservationExists = taskList.length > 0;
        }
        if (holidayList && holidayList.length) {
          reservationExists = holidayList.length > 0;
        }
        return reservationExists;
      } else {
        return false;
      }
    };

    onMounted(() => {
      setFullCalendarTitle();
      initialWeekStartDate.value = moment(
        fullCalendar.value?.getApi().view.currentStart
      ).format("YYYY-MM-DD");
      initialWeekEndDate.value = moment(
        fullCalendar.value?.getApi().view.currentEnd
      )
        .subtract(1, "day")
        .format("YYYY-MM-DD");
        let nextWeekClickCountLocal = localStorage.getItem("calendarPage");
        if(nextWeekClickCountLocal !== null){
          if( parseInt(nextWeekClickCountLocal) > 0){ //Checking which page of the calendar user is in
            let calendarApi = fullCalendar.value?.getApi();
            for(let i = 1; i<= parseInt(nextWeekClickCountLocal); i++){
                calendarApi?.next();
              }
            }
          }

      getStaffSchedule();
    });

    const getStaffSchedule = () => {
      loadingComponent = loading.show();
      currentCalendarStartDate.value = moment(
        fullCalendar.value?.getApi().view.currentStart
      ).format("YYYY-MM-DD");
      currentCalendarEndDate.value = moment(
        fullCalendar.value?.getApi().view.currentEnd
      )
        .subtract(1, "day")
        .format("YYYY-MM-DD");

      get(
        `api/v1/line/shiftSchedule?startDate=${currentCalendarStartDate.value}&endDate=${currentCalendarEndDate.value}&storeId=${store.getters.storeSelection.store.id}&consultation=${store.getters.storeSelection.consultationClassification}`
      ).then((res) => {
        if (res.status === 200) {
          scheduleHistoryData = res.data.data;
          if (scheduleHistoryData && scheduleHistoryData?.length > 0) {
            staffScheduleHistory = {
              staffSchedule: scheduleHistoryData?.map(
                (schedule: any) => schedule.shift
              ),
            };
            lineTime = {
                   lineSchedule: scheduleHistoryData?.map(
                  (schedule: any) => schedule.lineTime
                ),
            }
            updateEvents();
            loadingComponent.hide();
          } else {
            staffScheduleHistory = null;
            updateEvents();
            loadingComponent.hide();
          }
        }
      });
    };

    const setFullCalendarTitle = () => {
      document.getElementsByClassName("fc-timegrid-axis-frame")[0].innerHTML =
        "日時";
      let th = document.querySelector("th.fc-timegrid-axis");
    };

    const updateEvents = () => {
      let events: Array<{
        start: string;
        end: string;
        practitionerAvaibility: {
          schedule: Array<any>;
          count: PRACTITIONER_AVAILABILITY;
        };
      }> = [];
      let practitionerAvailableData: Array<{
        start: string;
        end: string;
        practitionerAvaibility: {
          schedule: Array<any>;
          count: PRACTITIONER_AVAILABILITY;
        };
      }> = [];
      let currentCalendarStartDate = moment(
        fullCalendar.value?.getApi().view.currentStart
      );
      let currentCalendarEndDate = moment(
        fullCalendar.value?.getApi().view.currentEnd
      ).subtract(1, "day");
      const startTime = getMaxStartTime();
      const endTime = getMaxEndTime();

      while (currentCalendarStartDate <= currentCalendarEndDate) {
        const momentStart = moment(startTime, "HH:mm:ss");
        const momentEnd = moment(endTime, "HH:mm:ss");
        while (momentStart <= momentEnd) {
          const start = `${currentCalendarStartDate.format(
            "YYYY-MM-DD"
          )}T${momentStart.format("HH:mm:ss")}`;
          const end = `${currentCalendarStartDate.format(
            "YYYY-MM-DD"
          )}T${momentStart
            .clone()
            // .add(
            //   storeSelection.consultationClassification ===
            //     CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
            //     ? 30
            //     : 60,
            //   "minutes"
            // )
            .add(30, "minutes")
            .format("HH:mm:ss")}`;
          practitionerAvailableData.push({
            start,
            end,
            practitionerAvaibility: getPractitionerAvaibility(start, end),
          });
          momentStart
            // .add(
            //   storeSelection.consultationClassification ===
            //     CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
            //     ? 30
            //     : 60,
            //   "minutes"
            // );
            .add(30, "minutes");
        }
        currentCalendarStartDate.add(1, "day");
      }
      practitionerAvailableData.forEach((currentAvailableDate, index) => {
        if (
          currentAvailableDate.practitionerAvaibility.count !==
          PRACTITIONER_AVAILABILITY.NOT_AVAILABLE
        ) {
          const nextAvailableDate = practitionerAvailableData[index + 1];
          if (nextAvailableDate) {
            if (
              moment(currentAvailableDate.start).isSame(
                nextAvailableDate.start,
                "day"
              )
            ) {
              if (
                storeSelection.consultationClassification ===
                CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
              ) {
                events.push(currentAvailableDate);
              } else {
              
                events.push({
                  ...currentAvailableDate,
                  practitionerAvaibility: {
                    schedule:
                      currentAvailableDate.practitionerAvaibility.schedule,
                    count:
                      practitionerAvailableData[index + 1]
                        .practitionerAvaibility.count ===
                      PRACTITIONER_AVAILABILITY.NOT_AVAILABLE
                        ? PRACTITIONER_AVAILABILITY.NOT_AVAILABLE
                        : getFirstVisitandRevisitCount(currentAvailableDate, nextAvailableDate),
                  },
                });
              }
            } else {
              if (
                storeSelection.consultationClassification ===
                CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
              ) {
                events.push(currentAvailableDate);
              } else {
                events.push({
                  ...currentAvailableDate,
                  practitionerAvaibility: {
                    schedule:
                      currentAvailableDate.practitionerAvaibility.schedule,
                    count: PRACTITIONER_AVAILABILITY.NOT_AVAILABLE,
                  },
                });
              }
            }
          } else {
            if (
              storeSelection.consultationClassification ===
              CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
            ) {
              events.push(currentAvailableDate);
            } else {
              events.push({
                ...currentAvailableDate,
                practitionerAvaibility: {
                  schedule:
                    currentAvailableDate.practitionerAvaibility.schedule,
                  count: PRACTITIONER_AVAILABILITY.NOT_AVAILABLE,
                },
              });
            }
          }
        } else {
          events.push(currentAvailableDate);
        }
      });

      calendarOptions.events = events;
      let initialCount = 'initial'; //Flag to check when the reservation changes from available(1) to unavailable(0)
      let firstValue = '' ;  // Variable to store the starttime of the first unavailable reservation past current time
      //  events.forEach((value, index) => {
      //    if(moment(value.start).format('L') === (moment().format('L'))){ // Checking only for todays date
      //     if (value.practitionerAvaibility.count === 0 && initialCount === 'initial') {
      //       initialCount = 'currentUnavailableReservations'; 
      //     } else if (value.practitionerAvaibility.count === 1 && initialCount === 'currentUnavailableReservations'){
      //       initialCount = 'currentAvailableReservations'
      //     } else if (value.practitionerAvaibility.count === 0 && initialCount === 'currentAvailableReservations'){
      //       firstValue = value.start;
      //       initialCount = 'exit' // exiting the else if loop
      //     }
      //   }
      // })
      // const index = events.findIndex(element => { //getting the index of the first unavailable reservation past current time
      //               if (element.start === firstValue) {
      //                 return true;
      //               }
      //                 return false;
      //             });

      // if(moment(firstValue).diff(currentThirtyHour, 'minutes') <= 60 && storeSelection.consultationClassification !== 2){ //Making the 1section(30min) slot unavailable for first time and reexamination, if present
      //          events[index - 1] = {
      //           end: events[index - 1].end,
      //           start: events[index - 1].start,
      //           practitionerAvaibility : {
      //               count : 0,
      //               schedule : events[index - 1].practitionerAvaibility.schedule,
      //           }
      //         }
      //       }


    };

     const getFirstVisitandRevisitCount = (current: any, next: any) =>{
       if(current.practitionerAvaibility.schedule.length === 0){
         return PRACTITIONER_AVAILABILITY.NOT_AVAILABLE;
       } else {
         const employeeIds = current.practitionerAvaibility.schedule.map((schedule: any)=>{
           return {
             employeeId: schedule.employeeId
           }
         })

        let employeeIdExistsInNext :any= [];
        employeeIds.forEach((EmpId: any)=>{
          const filteredSchedule = next.practitionerAvaibility.schedule.filter((nextEmpId: any) =>{
              return EmpId.employeeId === nextEmpId.employeeId;
          })[0]
          
         if(filteredSchedule){
           employeeIdExistsInNext.push(filteredSchedule)
         }
        })
    
        if(employeeIdExistsInNext.length >= 2){
            return PRACTITIONER_AVAILABILITY.MORE_THAN_TWO_AVAILABLE
          } else if (employeeIdExistsInNext.length === 1){
            return PRACTITIONER_AVAILABILITY.ONLY_ONE_AVAILABLE
          } else {
            return PRACTITIONER_AVAILABILITY.NOT_AVAILABLE
          }
       }
       
      }


    const getMaxStartTime = () => {
      const {
        store: { storeBusinessHours },
      } = storeSelection;
      const maxStartTime = storeBusinessHours
        // .filter(
        //   (businessHour: { isRegularHoliday: boolean }) =>
        //     !businessHour.isRegularHoliday
        // )
        .reduce((maxStartTime: string, currentBusinessHour: any) => {
          const startTime = moment(maxStartTime, "HH:mm:ss");
          const momentStart = moment(
            currentBusinessHour?.lineStartTime ||
              currentBusinessHour.openingTime,
            "HH:mm:ss"
          );
          if (momentStart < startTime) {
            maxStartTime = momentStart.format("HH:mm:ss");
          }
          return maxStartTime;
        }, "09:00:00");

      return maxStartTime || "09:00:00";
    };

    const getMaxEndTime = () => {
      const {
        store: { storeBusinessHours },
      } = storeSelection;
      const maxEndtime = storeBusinessHours
        // .filter(
        //   (businessHour: { isRegularHoliday: boolean }) =>
        //     !businessHour.isRegularHoliday
        // )
        .reduce((maxEndtime: string, currentBusinessHour: any) => {
          const endTime = moment(maxEndtime, "HH:mm:ss");
          const momentEnd = moment(
            currentBusinessHour?.lineEndTime ||
              currentBusinessHour?.closingTime,
            "HH:mm:ss"
          );
          if (momentEnd > endTime) {
            maxEndtime = momentEnd.format("HH:mm:ss");
          }
          return maxEndtime;
        }, "22:00:00");
      return maxEndtime || "22:00:00";
    };

    const prevWeek = () => {
      let calendarApi = fullCalendar.value?.getApi();
      let nextWeekClickCountLocal = localStorage.getItem('calendarPage');
      if(nextWeekClickCountLocal !== null){
        let convertLocalvaIntoInt = parseInt(nextWeekClickCountLocal) - 1; // Decreasing count when previous week button is clicked
        localStorage.setItem("calendarPage", JSON.stringify(convertLocalvaIntoInt));
      }

      calendarApi?.prev();
      getStaffSchedule();
    };

    const nextWeek = () => {
      let calendarApi = fullCalendar.value?.getApi();
      nextWeekClickCount = nextWeekClickCount + 1; // Increasing count when next week button is clicked
      localStorage.setItem("calendarPage", JSON.stringify(nextWeekClickCount));
      calendarApi?.next();
      getStaffSchedule();
    };

    const goBack = () => {
      router.back();
    };

    return {
      fullCalendar,
      initialWeekStartDate,
      initialWeekEndDate,
      currentCalendarStartDate,
      currentCalendarEndDate,
      circle,
      cross,
      triangle,
      storeSelection,
      //
      calendarOptions,
      currentEvents: [] as EventApi[],
      //
      prevWeek,
      nextWeek,
      goBack,
    };
  },
});
