import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import StoreSelection from "../views/StoreSelection.vue";
import LineIDLinkage from "../views/LineIDLinkage.vue";
import ReservationCalendar from "../views/ReservationCalendar.vue";
import ReservationInformation from "../views/ReservationInformation.vue";
import ReservationConfirmation from "../views/ReservationConfirmation.vue";
import CurrentReservation from "../views/CurrentReservation.vue";
import Maintenance from "../views/Maintenance.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "StoreSelection",
    component: StoreSelection,
  },
  {
    path: "/line-id-linkage",
    name: "LineIDLinkage",
    component: LineIDLinkage,
  },
  {
    path: "/reservation-calendar",
    name: "ReservationCalendar",
    component: ReservationCalendar,
  },
  {
    path: "/reservation-info",
    name: "ReservationInformation",
    component: ReservationInformation,
  },
  {
    path: "/reservation-confirmation",
    name: "ReservationConfirmation",
    component: ReservationConfirmation,
  },
  {
    path: "/current-reservation",
    name: "CurrentReservation",
    component: CurrentReservation,
  },
  {
    path: "/500",
    name: "Maintenance",
    component: Maintenance,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
